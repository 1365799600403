<template>
  <div class="customizedDevelopment">
    <img
      src="../../assets/product/customizedDevelopment/1.png"
      class="headerimg"
    />
    <div class="information">
      <img src="../../assets/product/customizedDevelopment/34.png" alt="" />
      <!-- <div style="display: flex;justify-content: space-between;">
				<div class="informationcard">
					<div class="informationnum">6</div>
					<div class="informationinfo">
						6年软件开发经验
					</div>
				</div>
				<div class="line"></div>
				<div class="informationcard">
					<div class="informationnum">20</div>
					<div class="informationinfo">
						20+行业解决方案
					</div>
				</div>
				<div class="line"></div>
				<div class="informationcard">
					<div class="informationnum">30</div>
					<div class="informationinfo">
						研发工作，产品思维
					</div>
				</div>
				<div class="line"></div>
				<div class="informationcard">
					<div class="informationnum">10</div>
					<div class="informationinfo">
						完整验收标准
					</div>
				</div>
				<div class="line"></div>
				<div class="informationcard">
					<div class="informationnum">1000+</div>
					<div class="informationinfo">
						1000+企业客户的选择
					</div>
				</div>
			</div> -->
    </div>
    <div class="serve">
      <div class="title">为企业提供软件定制服务</div>
      <div class="titletwo">
        服务于教育，新能源，电商新零售，医疗健康多个行业
      </div>

      <div class="servelist">
        <div class="servecard">
          <div class="serverimg">
            <img src="../../assets/product/customizedDevelopment/2.png" />
          </div>
          <div class="servecardtitle">APP定制开发</div>
          <div class="servecardinfo">
            为企业提供个性化APP定制开发服务 iOS端、Android端、混合开发等
          </div>
        </div>

        <div class="servecard">
          <div class="serverimg">
            <img src="../../assets/product/customizedDevelopment/3.png" />
          </div>
          <div class="servecardtitle">微信定制开发</div>
          <div class="servecardinfo">
            根据需求和行业特性，进行功能开发微信公众号、小程序、H5定制开发等
          </div>
        </div>

        <div class="servecard">
          <div class="serverimg">
            <img src="../../assets/product/customizedDevelopment/4.png" />
          </div>
          <div class="servecardtitle">软件系统定制开发</div>
          <div class="servecardinfo">
            教育软件、电商系统、汽车软件、物 联网软件、工业软件、管理系统、监
            控系统、调度系统、能耗系统等
          </div>
        </div>

        <div class="servecard">
          <div class="serverimg">
            <img src="../../assets/product/customizedDevelopment/5.png" />
          </div>
          <div class="servecardtitle">网站定制开发</div>
          <div class="servecardinfo">
            企业官网、集团网站、功能型网站、综合型网站定制开发等
          </div>
        </div>
      </div>
    </div>

    <div class="solution">
      <div class="title">垂直的行业解决方案 满足业务需求</div>
      <div class="titletwo">
        根据企业实际需求和痛点，提供1V1免费咨询，制定可行的行业解决方案，从需求根源真正解决问题。
      </div>

      <div
        class="solutionlist"
        :style="{
          backgroundImage: 'url(' + solutionList[solutionid].backimg + ')',
        }"
      >
        <div class="solutioncard">
          <div
            class="solutiontitle"
            v-for="(item, index) in solutionList"
            @click="solutionid = index"
            :class="solutionid == index ? 'online' : ''"
            :key="index"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="solutioninfo">
          <div class="solutioninfotitle">
            {{ solutionList[solutionid].title }}
          </div>
          <div class="solutioninfoinfo">
            {{ solutionList[solutionid].solutioninfoinfo }}
          </div>
          <div class="solutioninfoimg">
            <img
              :src="imgurl"
              v-for="(imgurl, index) in solutionList[solutionid]
                .solutioninfoimg"
              :key="index"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="where">
      <div class="title">为什么要做定制开发</div>

      <div class="wherelist">
        <div class="wherecard">
          <ul>
            <li>
              <span class="hex"
                ><span class="hexIn">
                  <img
                    src="../../assets/product/customizedDevelopment/7.png"
                    class="wherecardimg"
                  />
                  <div class="wherecardtitle">拒绝模板</div>
                  <div class="wherecardinfo">
                    多行业开发经验，了解企业痛点，定制化软件开发更符合用户需求
                  </div>
                </span></span
              >
            </li>
            <li>
              <span class="hex"
                ><span class="hexIn">
                  <img
                    src="../../assets/product/customizedDevelopment/8.png"
                    class="wherecardimg"
                  />
                  <div class="wherecardtitle">方便合理</div>
                  <div class="wherecardinfo">
                    梳理用户需求，结合用户真实情况和市场特征，量身打造合理方案
                  </div>
                </span></span
              >
            </li>
            <li>
              <span class="hex"
                ><span class="hexIn">
                  <img
                    src="../../assets/product/customizedDevelopment/9.png"
                    class="wherecardimg"
                  />
                  <div class="wherecardtitle">代码质量保证</div>
                  <div class="wherecardinfo">代码规范检查，多方测试验收</div>
                </span></span
              >
            </li>
            <li>
              <span class="hex"
                ><span class="hexIn">
                  <img
                    src="../../assets/product/customizedDevelopment/10.png"
                    class="wherecardimg"
                  />
                  <div class="wherecardtitle">项目按时交付</div>
                  <div class="wherecardinfo">
                    业务流程清晰透明，项目实时跟进，一站式开发服务，给用户省心体验
                  </div>
                </span></span
              >
            </li>
            <li>
              <span class="hex"
                ><span class="hexIn">
                  <img
                    src="../../assets/product/customizedDevelopment/7.png"
                    class="wherecardimg"
                  />
                  <div class="wherecardtitle">软件灵活迭代</div>
                  <div class="wherecardinfo">
                    源代码交付，软件升级无后顾之忧
                  </div>
                </span></span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="exploitation">
      <div class="title">定制开发为什么选邦伲德</div>

      <div class="exploitationtable">
        <div class="exploitationline">
          <div class="backcolor">PK</div>
          <div class="backcolorone">方案</div>
          <div class="backcolor">服务</div>
          <div class="backcolorone">专业</div>
          <div class="backcolor">收费</div>
          <div class="backcolorone">保障</div>
        </div>
        <div class="exploitationlinetwo">
          <div class="backcolortwo" style="color: #fff">邦伲德</div>
          <div class="backcolorone">按需定制开发方案</div>
          <div class="backcolor" style="background-color: #fff">
            IT顾问、产品经理全程跟进
          </div>
          <div class="backcolorone">为客户提供专业建议，打造MVP</div>
          <div class="backcolor" style="background-color: #fff">
            收费透明，签订正规合同，开具发票
          </div>
          <div class="backcolorone">项目跟进实时同步终身bug维护</div>
        </div>
        <div class="exploitationlinethree">
          <div class="backcolor">其他公司</div>
          <div class="backcolorone">方案固定、模板修改</div>
          <div class="backcolor">多对一服务，工期无法保证</div>
          <div class="backcolorone">无专业建议</div>
          <div class="backcolor">多次收费、后续服务不稳定</div>
          <div class="backcolorone">不能及时响应，后续开发有疑问</div>
        </div>
      </div>
    </div>

    <div class="competence">
      <div class="title">邦伲德核心能力</div>
      <div class="competencetable">
        <div>
          <div class="competencetitle">信息化方案咨询能力</div>
          <div class="competencetabletitle">
            根据企业实际需求和痛点，提供轻量级咨询，制定合适的产品技术方案，解决企业真正问题。
          </div>
          <div class="competencetableinfo">
            <div class="line">
              <div class="circle"></div>
              需求驱动 - 从需求根源真正解决问题
            </div>
            <div class="line">
              <div class="circle"></div>
              精益思维 - 制定可执行、可迭代的方案
            </div>
            <div class="line">
              <div class="circle"></div>
              经验积累 - 基于数千次成功咨询落地经验
            </div>
          </div>
        </div>
        <div>
          <div class="competencetitle">技术研发与交付能力</div>
          <div class="competencetabletitle">
            通过完善的系统架构、底层框架、和自动化研发周期管理，让解决方案快速、高质、可持续维护的实现落地。
          </div>
          <div class="competencetableinfo">
            <div class="line">
              <div class="circle"></div>
              敏捷开发 - 以周为单位的开发周期管控
            </div>
            <div class="line">
              <div class="circle"></div>
              持续集成 - 自动化DevOps服务器运维管理
            </div>
            <div class="line">
              <div class="circle"></div>
              质量监控 - 架构审核、代码审核、集成测试
            </div>
          </div>
        </div>
      </div>
      <div class="competenceimg">
        <img src="../../assets/product/customizedDevelopment/12.png" />
      </div>
    </div>

    <div class="serve">
      <div class="title">服务流程</div>
      <div class="serveimg">
        <img src="../../assets/product/customizedDevelopment/13.png" />
      </div>
    </div>

    <div>
      <div class="method">
        <div class="methodback">
          <div class="title">邦伲德快速开发交付方法论</div>
          <div class="methodtitle">四步法:四个阶段，六个角色</div>
          <div class="methodtable">
            <div class="methodlist">
              <div class="methodlistheader">
                <div>第一阶段:开工</div>
                <div class="methodlistheadertext">《开工确认单》</div>
              </div>
              <div class="methodlistimg">
                <img src="../../assets/product/customizedDevelopment/28.png" />
              </div>
              <div class="methodinfo">
                <div class="methodinfoheader">项目经理</div>
                <div class="info">
                  <div class="infoline">1.甲乙双方组建项目团队</div>
                  <div class="infoline">2.甲乙双方任命项目经理</div>
                  <div class="infoline">3.甲乙双方建立项目工作机制</div>
                  <div class="infoline">4.甲乙项目经理沟通和确认需求</div>
                  <div class="infoline">5.甲乙双方设定阶段目标</div>
                  <div class="infoline">6.甲乙双方共同制定开发计划</div>
                  <div class="infoline">7.甲乙双方明确分工和要求</div>
                </div>
                <div class="infobtm">《开工确认单》是项目正式开始的标志</div>
              </div>
            </div>

            <div class="methodlist">
              <div class="methodlistheader">
                <div>第二阶段:原型</div>
                <div class="methodlistheadertext">《需求原型确认单》</div>
              </div>
              <div class="methodlistimg">
                <img src="../../assets/product/customizedDevelopment/28.png" />
              </div>
              <div class="methodinfo">
                <div class="methodinfoheader">项目经理</div>
                <div class="info">
                  <div class="infoline">1.甲乙双方沟通和确认需求</div>
                  <div class="infoline">2.乙方项目经理提交原型图</div>
                  <div class="infoline">3.甲方项目经理确认原型图</div>
                </div>
                <div class="methodinfoheader">项目经理</div>
                <div class="info">
                  <div class="infoline">1.根据需求框架制作系统原型图</div>
                  <div class="infoline">2.乙方提交，甲方确认原型图</div>
                </div>
              </div>
            </div>

            <div class="methodlist">
              <div class="methodlistheader">
                <div>第三阶段:设计</div>
                <div class="methodlistheadertext">《UI设计确认单》</div>
              </div>
              <div class="methodlistimg">
                <img src="../../assets/product/customizedDevelopment/28.png" />
              </div>
              <div class="methodinfo">
                <div class="methodinfoheader">项目经理</div>
                <div class="info">
                  <div class="infoline">1.甲乙双方沟通和确认UI风格</div>
                  <div class="infoline">2.乙方项目经理提交UI设计稿</div>
                  <div class="infoline">3.甲方项目经理确认UI设计稿</div>
                </div>
                <div class="methodinfoheader">项目经理</div>
                <div class="info">
                  <div class="infoline">1.根据甲方审美要求设计界面</div>
                  <div class="infoline">2.乙方提交，甲方确认设计稿</div>
                </div>
              </div>
            </div>

            <div class="methodlist">
              <div class="methodlistheader">
                <div>第四阶段:开发并测试</div>
                <div class="methodlistheadertext">《测试报告确认单》</div>
              </div>
              <div class="methodlistimg">
                <img src="../../assets/product/customizedDevelopment/28.png" />
              </div>
              <div class="methodinfo">
                <div class="methodinfoheader">项目经理</div>
                <div class="info">
                  <div class="infoline">1.乙方完成代码开发，测试和修复</div>
                  <div class="infoline">2.乙方测试合格后，提交测试报告</div>
                  <div class="infoline">3.甲方项目经理在测试报告上签字</div>
                </div>
                <div class="methodinfoheader">前端开发工程师</div>
                <div class="info">
                  <div class="infoline">1.根据需求对前端进行开发</div>
                </div>
                <div class="methodinfoheader">后端开发工程师</div>
                <div class="info">
                  <div class="infoline">1.根据需求对后台进行开发</div>
                </div>
                <div class="methodinfoheader">系统测试工程师</div>
                <div class="info">
                  <div class="infoline">1.对系统进行测试，提出修复任务</div>
                  <div class="infoline">2.测试合格后，想甲方提交测试报告</div>
                </div>
                <div class="infobtm">《测试报告确认单》是项目开发的标志</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="prosperity">
        <div class="prosperitycard">
          <div class="prosperityinfo">
            <div class="prosperityinfotitle">项目成功的核心要素</div>
            <div class="prosperityinfotitle">四个阶段</div>
            <div class="prosperityinfotext">开工、原型，设计，开发与测试</div>
            <div class="prosperityinfotitle">六个角色</div>
            <div class="prosperityinfotext">项目经理，产品经理，u设计师</div>
            <div class="prosperityinfotext">前端开发工程师</div>
            <div class="prosperityinfotext">JAVA开发工程师和系统测试工程师</div>
          </div>
        </div>
      </div>
    </div>

    <div class="deliverables">
      <div class="title">交付清单</div>

      <div class="deliverableslist">
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/15.png" />
          <div class="deliverablestext">源代码</div>
        </div>
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/16.png" />
          <div class="deliverablestext">产品原型及需求文档</div>
        </div>
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/17.png" />
          <div class="deliverablestext">产品原型及Axure文件</div>
        </div>
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/18.png" />
          <div class="deliverablestext">UI设计效果图及Sketch源文件</div>
        </div>
      </div>

      <div class="deliverableslist">
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/19.png" />
          <div class="deliverablestext">技术部署文档</div>
        </div>
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/20.png" />
          <div class="deliverablestext">数据说明文档</div>
        </div>
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/21.png" />
          <div class="deliverablestext">接口说明文档</div>
        </div>
        <div class="deliverablescard">
          <img src="../../assets/product/customizedDevelopment/22.png" />
          <div class="deliverablestext">测试方案文档</div>
        </div>
      </div>
    </div>

    <div class="partner">
      <div class="title">合作伙伴</div>
      <div class="partnerlist">
        <img src="../../assets/product/customizedDevelopment/23.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      solutionid: 0,
      solutionList: [
        {
          title: "工业制造",
          solutioninfoinfo:
            "邦伲德为工业制造业提供定制化智能制造解决方案，助力制造型企业从低端价值链向高端价 值链的转型，早日实现信息化转型、数字化转型和智能化转型",
          backimg: require("../../assets/product/customizedDevelopment/32.png"),
          solutioninfoimg: [
            require("../../assets/product/customizedDevelopment/g1.png"),
            require("../../assets/product/customizedDevelopment/q1.png"),
            require("../../assets/product/customizedDevelopment/q3.png"),
          ],
        },
        {
          title: "教育",
          solutioninfoinfo:
            "提供在线教育解决方案，打通线上线下用户体系，帮助机构实现互联网转型。",
          backimg: require("../../assets/product/customizedDevelopment/6.png"),
          solutioninfoimg: [
            require("../../assets/product/customizedDevelopment/24.png"),
            require("../../assets/product/customizedDevelopment/25.png"),
            require("../../assets/product/customizedDevelopment/26.png"),
            require("../../assets/product/customizedDevelopment/27.png"),
          ],
        },
        {
          title: "汽车",
          solutioninfoinfo:
            "满足汽车行业客户个性化的需求，适应未来的发展，提供行业系统软件管理体系、整体的汽车行业 信息化解决方案",
          backimg: require("../../assets/product/customizedDevelopment/30.png"),
          solutioninfoimg: [
            require("../../assets/product/customizedDevelopment/z1.png"),
            require("../../assets/product/customizedDevelopment/z2.png"),
          ],
        },
        {
          title: "智慧交通",
          solutioninfoinfo:
            "基于“人、车、路”等关键要素，自动识别、智能分析交通重点管理目标、自动检出违法行为，提 高通行效率，优化交通出行秩序",
          backimg: require("../../assets/product/customizedDevelopment/31.png"),
          solutioninfoimg: [
            require("../../assets/product/customizedDevelopment/q2.png"),
            require("../../assets/product/customizedDevelopment/27.png"),
          ],
        },
        {
          title: "零售",
          solutioninfoinfo:
            "基于零售场景，提供定制化方案，支撑新零售全域消费者营销运营",
          backimg: require("../../assets/product/customizedDevelopment/33.png"),
          solutioninfoimg: [
            require("../../assets/product/customizedDevelopment/g2.png"),
          ],
        },
        {
          title: "医疗",
          solutioninfoinfo:
            "在医疗卫生领域，面向医疗卫生生态体系，构建解决方案并提供服务",
          backimg: require("../../assets/product/customizedDevelopment/29.png"),
          solutioninfoimg: [
            require("../../assets/product/customizedDevelopment/y1.png"),
            require("../../assets/product/customizedDevelopment/y2.png"),
          ],
        },
      ],
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.partner {
  margin-top: 140px;
  .partnerlist {
    margin-top: 60px;
    margin-bottom: 140px;
    text-align: center;
    img {
      width: 78%;
    }
  }
}

.deliverables {
  margin-top: 180px;
  padding-top: 90px;
  padding-bottom: 99px;
  background-color: #f5f7fa;
  .deliverableslist {
    margin: auto;
    margin-top: 20px;
    padding-bottom: 20px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    .deliverablescard {
      width: 324px;
      height: 160px;
      text-align: center;
      background-color: #fff;
      img {
        margin-top: 40px;
        width: 50px;
      }
      .deliverablestext {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 24px;
      }
    }
  }
}

.prosperity {
  background-color: #2f7ffc;
  width: 424px;
  height: 459px;
  padding-top: 40px;
  box-sizing: border-box;
  margin-top: -205px;
  margin-left: 471px;
  .prosperitycard {
    width: 517px;
    height: 459px;
    background: #ffffff;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.34);
    margin-left: -150px;
    box-sizing: border-box;
    position: absolute;
    .prosperityinfo {
      margin: 40px;
      margin-left: 23px;
      border: 1px solid #2f7ffc;
      height: 379px;
      text-align: center;
      color: #2f7ffc;
      .prosperityinfotitle {
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        margin-top: 40px;
      }
      .prosperityinfotext {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 10px;
      }
    }
  }
}
.method {
  margin-top: 140px;
  padding: 0px 260px;
  background-color: #4c4c4c;

  .methodback {
    background-image: url(../../assets/product/customizedDevelopment/14.png);
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 90px;
    padding-bottom: 63px;
    color: #fff;
    .methodtitle {
      text-align: center;
      margin-top: 23px;
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
    }
    .methodtable {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      .methodlist {
        width: 312px;
        font-size: 14px;
        font-weight: 400;
        .methodlistheader {
          padding-top: 22px;
          box-sizing: border-box;
          background-color: #2b65b3;
          line-height: 20px;
          height: 94px;
          text-align: center;
          .methodlistheadertext {
            margin-top: 10px;
          }
        }
        .methodlistimg {
          text-align: center;
          img {
            width: 26px;
          }
        }

        .methodinfo {
          border: 1px solid #fff;
          min-height: 349px;
          box-sizing: border-box;
          padding: 20px 25px;
          .methodinfoheader {
            background-color: #7891b1;
            text-align: center;
            height: 49px;
            line-height: 49px;
            margin-bottom: 20px;
          }
          .infoline {
            line-height: 20px;
            margin-bottom: 10px;
          }
        }
        .infobtm {
          margin-top: 10px;
        }
      }
    }
  }
}

.serve {
  margin-top: 140px;
  .serveimg {
    text-align: center;
    margin-top: 60px;
    img {
      width: 74%;
    }
  }
}

.competence {
  margin-top: 140px;
  .competenceimg {
    z-index: -99;
    position: relative;
    text-align: center;
    margin-top: -105px;
    img {
      width: 74%;
    }
  }
  .competencetable {
    z-index: 99;
    display: flex;
    justify-content: space-between;
    width: 74%;
    margin: auto;
    margin-top: 60px;
    .circle {
      width: 10px;
      height: 10px;
      display: inline-block;
      background-color: #2f7ffc;
      margin-right: 17px;
      border-radius: 50%;
    }
    .competencetitle {
      font-size: 24px;
      font-weight: 400;
      line-height: 33px;
    }
    .competencetabletitle {
      width: 600px;
      font-size: 14px;
      font-weight: 400;
      color: #616b80;
      line-height: 20px;
      margin-top: 30px;
    }
    .competencetableinfo {
      margin-top: 30px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      .line {
        margin-bottom: 12px;
      }
    }
  }
}

.exploitation {
  margin: auto;
  margin-top: 140px;
  width: 80%;
  .backcolor {
    background-color: #c4d5ff;
  }
  .backcolorone {
    background-color: #e0eaff;
  }
  .backcolortwo {
    background: linear-gradient(180deg, #7fa4fe 0%, #2262fd 100%);
  }
  .exploitationtable {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    .exploitationline {
      width: 184px;
      div {
        height: 86px;
        text-align: center;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2f7ffc;
        line-height: 86px;
      }
    }
    .exploitationlinetwo {
      width: 608px;
      div {
        height: 86px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #2f7ffc;
        line-height: 86px;
      }
    }
    .exploitationlinethree {
      width: 608px;
      div {
        height: 86px;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        color: #94adf0;
        line-height: 86px;
      }
    }
  }
}

.where {
  margin-top: 103px;
  padding-top: 127px;
  padding-bottom: 100px;
  background: #f5f7fa;

  .wherelist {
    width: 70%;
    display: flex;
    margin: auto;
    margin-top: 60px;
    justify-content: center;
    .wherecard {
      .wherecardimg {
        width: 100px;
        margin-top: 88px;
      }
      .wherecardtitle {
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        margin-top: 30px;
      }

      .wherecardinfo {
        width: 262px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin: auto;
        margin-top: 20px;
      }
      ul {
        margin: 0;
        padding: 0;
      }

      ul {
        list-style: none;
        // width: 660px;
        // margin: 200px auto;
      }

      li {
        float: left;
        margin: 0 5px;
        margin-left: 28px;
        height: 396px;
      }

      .hex {
        overflow: hidden;
        display: block;
        width: 389px;
        height: 450px;
        transform: rotate(-60deg) skewY(29deg);
      }
      // 高度等于宽度*1.155
      .hexIn {
        background-color: #fff;
        display: block;
        width: 389px;
        height: 450px;
        // line-height: 390px;
        text-align: center;
        transform: skewY(-30deg) rotate(60deg);
      }

      li:nth-child(4n + 0) {
        margin-left: 250px;
      }
    }
  }
}

.solution {
  margin-top: 140px;

  .solutionlist {
    margin: auto;
    margin-top: 40px;
    background-size: 100%;
    background-repeat: no-repeat;
    width: 80%;
    height: 530px;
    display: flex;

    .solutioncard {
      height: 100%;
      background: rgba(0, 0, 0, 0.44);
      width: 196px;
      text-align: center;

      .solutiontitle {
        cursor: pointer;
        font-size: 24px;
        font-weight: 400;
        color: #ffffff;
        line-height: 33px;
        padding: 20px;
      }
      .online {
        background: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    }

    .solutioninfo {
      padding-left: 140px;
      color: #ffffff;
      box-sizing: border-box;
      width: 80%;
      .solutioninfotitle {
        font-size: 36px;
        font-weight: 500;

        line-height: 50px;
        margin-top: 150px;
      }

      .solutioninfoinfo {
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        margin-top: 70px;
      }

      .solutioninfoimg {
        margin-top: 41px;

        img {
          width: 161px;
          height: 50px;
          margin-right: 40px;
          background-color: #fff;
          border-radius: 10px;
        }
      }
    }
  }
}

.information {
  // box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  padding-top: 0px;

  // border: 1px solid #000000;
  background: #ffffff;
  border-top: none;
  width: 80%;
  margin: auto;
  img {
    width: 80%;
    margin-top: -90px;
    position: absolute;
  }
  .line {
    background-color: #979797;
    width: 1px;
  }

  .informationcard {
    text-align: center;

    .informationnum {
      font-size: 70px;
      font-weight: 900;
      color: #2f7ffc;
      line-height: 91px;
    }

    .informationinfo {
      margin-top: 6px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.serve {
  margin-top: 140px;

  .servelist {
    width: 80%;
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .servecard {
      width: 325px;
      height: 335px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      text-align: center;
      box-sizing: border-box;
      padding: 18px;
      padding-top: 61px;
      padding-bottom: 80px;

      .serverimg {
        img {
          width: 70px;
        }
      }

      .servecardtitle {
        margin-top: 40px;
        font-size: 24px;
        font-weight: 500;
        color: #333333;
        line-height: 36px;
      }

      .servecardinfo {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
        margin-top: 20px;
      }
    }
  }
}

.title {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  line-height: 54px;
}

.titletwo {
  font-size: 24px;
  font-weight: 400;
  color: #616b80;
  line-height: 33px;
  text-align: center;
  margin-top: 20px;
}
</style>
